<template>
  <div class="login-div">
    <b-navbar></b-navbar>

    <div class="login-card-div p-5">
      <!-- <div class="mx-4 px-4 py-3">
        <label class="login-input-label">Email Address</label>
        <w-text-input
          :fieldType="'email'"
          :disabled="false"
          :defaultValue="emailValue"
          :labelStyle="'register-label'"
          :validation="''"
          :placeholder="'Enter Email Address'"
          :fieldName="'Email Address'"
          @textInputChange="getEmailAddress($event)"
        />
      </div> -->
      <div class="form-row">
          <div class="col d-flex flex-column">
            <label for="email">Email <span class="label-star">*</span></label>
            <validation-provider
              name="email"
              :rules="{ required: true, email: true, max: 255 }"
              :vid="emailValue"
            >
              <div slot-scope="{ errors }">
                <b-input
                  class="form-control"
                  type="email"
                  id="emailId"
                  placeholder="Enter your email address"
                  v-model="emailValue"
                  ref="email"
                />
                <p class="text-danger mb-0 float-left" style="font-size:12px">{{ errors[0] }}</p>
              </div>
            </validation-provider>
          </div>
        </div>
      <div class="py-3">
        <b-button
          class="w-100"
          variant="danger"
          @click="resetPassword()"
          :disabled="isloginDetail"
          >Reset Password</b-button
        >
      </div>
    </div>

    <!-- error modal -->
    <Modal
      :trigger="alert"
      :title="'HectoCommerce'"
      :centered="true"
      :showModalTable="false"
      :dontCloseWhenClickedOutside="true"
      :dontCloseWhenEscapePressed="true"
      :dontShowCloseIcon="true"
      :modalbuttonStyle="true"
      :alert="alert"
      :alertMsg="alertMsg"
      :alertType="alertType"
      :isConfirmAlert="isConfirmAlert"
      @eventClose="closeModal()"
    />
  </div>
</template>

<script>
import Navbar from "../../widgets/BrandNavbar";
import TextInput from "../../widgets/TextInput.vue";
import Modal from "../../widgets/ModalWidget";
import { resetPasswordInit } from "../../Service/LoginService";
export default {
  components: {
    "b-navbar": Navbar,
    "w-text-input": TextInput,
    Modal,
  },
  data() {
    return {
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,

      emailValue: "",
    };
  },

  computed: {
    isloginDetail() {
        let re = /\S+@\S+\.\S+\S+/;
        return !re.test(this.emailValue);

    //   if (!this.emailValue == "") {
    //     return false;
    //   }
    //   return true;
    },
  },

  methods: {
    // getEmailAddress(event) {
    //   this.emailValue = event;
    // },

    resetPassword() {
      let data = {
        mail: this.emailValue,
      };
      resetPasswordInit(data)
        .then((res) => {
          this.alert = true;
          this.isConfirmAlert = false;
          this.alertMsg = "Please check your Email and click on the link";
          this.alertType = "Success";

          this.emailValue="";
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // close modal
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },
  },
};
</script>

<style scoped>
.login-card-div {
  width: 30%;
  position: absolute;
  background: white;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-input-label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: 600;
  float: left;
  color: black;
}
.login-link-anchor {
  color: red;
  font-size: 13px;
  cursor: pointer;
}
.login-link-anchor:hover {
  color: red;
  text-decoration: underline;
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  .login-card-div {
    top: 55% !important;
    width: 75% !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .login-card-div {
    top: 55% !important;
    width: 95% !important;
    padding: 5px !important;
  }
  ::v-deep .brand-navbar .navbar-nav {
    width: 100% !important;
  }
  ::v-deep .brand-navbar .logo {
    margin-left: 0;
    font-size: 20px;
  }
  ::v-deep .navbar-toggler {
    padding: 0.25rem 0.5rem !important;
    font-size: 12px !important;
  }
}
</style>
